import React from "react";
import { graphql } from "gatsby";
import { gsap } from "gsap";
import Layout from "../components/layout";
import SEO from "../components/seo";
//import Slider1 from "../components/Slider-1.js"
import Slider2 from "../components/Slider-2.js";
import Slider3 from "../components/Slider-3.js";
import Newsletter from "../components/newsletter.js";
import CTA from "../components/cta.js";

class AboutPage extends React.Component {
  componentDidMount() {
    gsap.to("#a1", { x: 500, duration: 0 });
    gsap.to("#a1", { x: 0, duration: 5 });
    gsap.to("#a2", { x: -500, duration: 0 });
    gsap.to("#a2", { x: 0, duration: 5, delay: 1 });
    gsap.to(".a5", { y: -300, duration: 0 });

    initCount();
  }
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="O nas" />

        <div className="x-wrapper">
          <div
            className="columns"
            style={{
              minHeight: "500px",
              padding: "0px",
              margin: "0px",
              alignItems: "center",
              backgroundColor: "white"
            }}
          >
            <div
              id="lcol"
              className="column nopad"
              style={{
                justifyContent: "center",
                padding: "2% 0%",
                maxWidth: "25%"
              }}
            >
              <div className="nopadleft" style={{ paddingLeft: "3rem" }}>
                <h1
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2em",
                    marginTop: "30px"
                  }}
                  className="title"
                >
                  <strong style={{ color: "#00ff00" }}>O NAS</strong>
                  <br />{" "}
                  <span style={{ fontSize: ".5em" }}>
                    <b>KREUJEMY</b> ŚRODOWISKO
                    <br /> W TROSCE O <b>KOMFORT</b>
                    <br /> I BEZPIECZEŃSTWO
                    <br /> NASZYCH <b>KLIENTÓW</b>
                  </span>
                </h1>
                <hr
                  style={{
                    maxWidth: "150px",
                    backgroundColor: "#333333",
                    height: "3px"
                  }}
                />
                <div
                  className="show-mob"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <img
                    id="mobile-x"
                    src="/img/x.svg"
                    style={{
                      marginBottom: "20px",
                      maxWidth: "500px",
                      marginTop: "-75px"
                    }}
                    width="auto"
                    height="auto"
                    alt="Rexer engineering"
                  />
                </div>
                <p>
                  <strong>Firma REXER </strong>powstała w 2014 roku we Wrocławiu
                  z inicjatywy specjalistów z dziedziny inżynierii środowiska.
                  <br />
                  <br />
                  Dzięki 20-letniemu doświadczeniu i zdobytej wiedzy od samego
                  początku działalności stawiamy na optymalne rozwiązania
                  obniżające koszty, zarówno inwestycyjne jak i eksploatacyjne,
                  naszych Klientów.
                </p>
                <a href="#main" className="button rexer-button">
                  {" "}
                  Poznaj naszą historię
                </a>
              </div>
              <br />
              <div
                className="column mob-hide is-two-thirds"
                style={{ position: "relative" }}
              >
                <img
                  id="a2"
                  style={{
                    marginTop: "-150px",
                    marginBottom: "-30%",
                    marginLeft: "-20px",
                    maxWidth: "550px",
                    opacity: "1"
                  }}
                  src="/img/hand-bulb.png"
                  width="auto"
                  alt="animacja-kula"
                />
              </div>
            </div>

            <div
              id="midcol"
              className="column"
              style={{ width: "50%", marginTop: "-100px", textAlign: "center" }}
            >
              <img
                src="/img/x.svg"
                style={{ marginBottom: "0" }}
                width="auto"
                height="auto"
                alt="Rexer engineering"
              />
              <p
                id="paddmob"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <a
                  href="#main"
                  style={{ border: "0px solid white", marginTop: "0px" }}
                  className="button standard-button"
                >
                  ↓
                </a>
              </p>
            </div>

            <div
              id="rcol"
              className="column"
              style={{
                maxWidth: "25%",
                margin: "0px",
                marginTop: "-275px",
                padding: "0px",
                alignItems: "end",
                display: "block",
                justifyContent: "center",
                marginRight: "3em"
              }}
            >
              <div style={{ textAlign: "right" }}>
                <img
                  id="a1"
                  src="/img/o1.png"
                  width="100%"
                  height="auto"
                  alt="Rexer engineering"
                  style={{
                    minWidth: "300px",
                    maxWidth: "400px",
                    opacity: "1",
                    marginBottom: "50px",
                    margin: "0px",
                    borderBottom: "3px solid #f6f6f6",
                    backgroundImage: "url(/img/hero-bg-home.png)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                  }}
                />
              </div>
              <div
                className="deco-w"
                style={{
                  border: "5px solid #333333",
                  padding: "20px",
                  marginTop: "3em",
                  marginLeft: "1rem"
                }}
              >
                <p
                  className="deco"
                  style={{ textAlign: "right", backgroundColor: "white" }}
                >
                  Jesteśmy wytrwali, kreatywni i pełni pasji
                </p>
              </div>
              <p
                id="marginmob"
                style={{ textAlign: "right", marginTop: "3em" }}
              >
                <a className="button rexer-button" href="#team">
                  Poznaj nasz zespół
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          id="main"
          className="is-bold"
          style={{
            textAlign: "left",
            backgroundColor: "#f6f6f6",
            padding: "5%"
          }}
        >
          <h2
            className="subtitle is-size-5"
            style={{
              borderLeft: "5px solid #00ff00",
              marginTop: "50px",
              paddingLeft: "10px",
              marginLeft: "-10px"
            }}
          >
            <strong style={{ textTransform: "uppercase" }}>
              PODEJMUJEMY SIĘ REALIZACJI NAWET NAJTRUDNIEJSZYCH ZADAŃ
            </strong>
          </h2>
          <br />

          <p className="is-size-5">
            Zatrudniamy najlepszych fachowców, w tym przede wszystkim kadrę
            inżynierską posiadającą niezbędne, wieloletnie praktyczne
            doświadczenie. <br /> <br />
            Posiadamy wszystkie wymagane uprawnienia, tym samym{" "}
            <strong>
              gwarantujemy najwyższą jakość oraz niezawodność
            </strong>{" "}
            wykonywanych instalacji.
          </p>
          <br />
          <p className="is-size-5">
            Niezależnie od wielkości projektu stawiamy na dobrą współpracę i
            partnerskie stosunki z Klientem – promując równolegle rozwiązania
            przyjazne środowisku na terenie całej Polski.
          </p>
        </div>

        <iframe
          id="t-p"
          title="particles"
          loading="lazy"
          src="/particles-black.html"
          frameBorder="0"
          style={{
            width: "100%",
            minHeight: "375px",
            position: "relative",
            zIndex: "1"
          }}
        ></iframe>
        <div
          id="t-p-w"
          style={{
            marginTop: "-400px",
            textAlign: "center",
            padding: "5%",
            position: "relative",
            zIndex: "1"
          }}
        >
          <section
            className="is-bold is-medium animate"
            style={{ marginTop: "-7px" }}
          >
            <div className="hero-body">
              <div className="container" style={{ justifyContent: "center" }}>
                <div
                  className="columns timers"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    width: "100%"
                  }}
                >
                  <div className="column">
                    <p style={{ color: "#1111111" }}>
                      <b>
                        Lat <br />
                        na rynku
                      </b>
                    </p>
                    <br />
                    <h2
                      style={{
                        color: "white",
                        textShadow: "white 1px 0px 15px"
                      }}
                      id="t1"
                      className="is-size-3 timer-num"
                    >
                      {new Date().getFullYear() - 2014}
                    </h2>
                  </div>

                  <div className="column">
                    <p style={{ color: "#1111111" }}>
                      <b>
                        Stałych
                        <br /> klientów
                      </b>
                    </p>
                    <br />
                    <h2
                      style={{
                        color: "white",
                        textShadow: "white 1px 0px 15px"
                      }}
                      id="t2"
                      className="is-size-3 timer-num"
                    >
                      21
                    </h2>
                  </div>

                  <div className="column">
                    <p style={{ color: "#1111111" }}>
                      <b>
                        Zrealizowanych
                        <br /> Projektów
                      </b>
                    </p>
                    <br />
                    <h2
                      style={{
                        color: "white",
                        textShadow: "white 1px 0px 15px"
                      }}
                      id="t3"
                      className="is-size-3 timer-num"
                    >
                      66
                    </h2>
                  </div>

                  <div className="column">
                    <p style={{ color: "#1111111" }}>
                      <b>
                        Wartość
                        <br /> wykonanych instalacji
                      </b>
                    </p>
                    <br />
                    <h2
                      style={{
                        color: "white",
                        textShadow: "white 1px 0px 15px"
                      }}
                      id="t4"
                      className="is-size-3 timer-num"
                    >
                      120 000 000
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <br />
        <Slider3 />

        <div
          className="is-bold"
          style={{ textAlign: "center", padding: "2.5% 5%" }}
        >
          <p className="is-size-3">Mamy na koncie wyróżnienia i nagrody!</p>
          <p>
            Nasza praca na przestrzeni lat działalności została niejednokrotnie
            doceniona.
          </p>
        </div>

        <Slider2 />

        <div
          className="is-bold"
          id="team"
          style={{ textAlign: "center", padding: "2.5% 5% 2.5% 5%" }}
        >
          {/*<div className="animate" style={{textAlign:'center'}}>
      <div className="a5" style={{zIndex:'0',position:'relative'}}>
        <img width="200px" height="auto" src="/img/helmet.png" alt="zespół" />
      </div>
      </div>
      <h2 className="title">ZESPÓŁ</h2>
      <h3 className="subtitle is-size-4">..to dopiero początek!</h3>
      <br /><br />*/}
          {/*TODO: ADD TO CMS*/}

          <div className="card">
            <header
              className="card-header"
              style={{ alignItems: "center", backgroundColor: "#00ff00" }}
            >
              <h2
                className="card-header-title subtitle is-size-3"
                style={{ margin: "10px", color: "white" }}
              >
                Kadra zarządzająca Spółek
              </h2>
            </header>
            <h3
              style={{
                textAlign: "left",
                margin: "1%",
                marginTop: "2%",
                paddingLeft: "10px",
                borderLeft: "2px solid #00ff00"
              }}
              className="subtitle is-size-6"
            >
              ZARZĄD{" "}
            </h3>
            <div className="columns" style={{ margin: "0" }}>
              <div
                className="column mob-hide"
                style={{ textAlign: "center", margin: "1%" }}
              ></div>

              <div
                className="column is-3"
                style={{
                  textAlign: "center",
                  backgroundColor: "#f6f6f6",
                  margin: "1%"
                }}
              >
                <img
                  src="/img/user-regular.svg"
                  className="timer-num"
                  style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                  width="100px"
                  alt="person foto"
                />
                <br />
                <p className="subtitle">
                  Radosław Gruszecki
                  <br />
                  <sub>
                    Prezes Zarządu
                    <br /> Dyrektor Generalny
                  </sub>
                </p>
                <br />
              </div>

              <div
                className="column is-3"
                style={{
                  textAlign: "center",
                  backgroundColor: "#f6f6f6",
                  margin: "1%"
                }}
              >
                <img
                  src="/img/user-regular.svg"
                  className="timer-num"
                  style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                  width="100px"
                  alt="person foto"
                />
                <br />
                <p className="subtitle">
                  Piotr Kalisz
                  <br />
                  <sub>
                    Wiceprezes Zarządu
                    <br /> Dyrektor Sprzedaży
                  </sub>
                </p>
                <br />
              </div>

              <div
                className="column mob-hide"
                style={{ textAlign: "center", margin: "1%" }}
              ></div>
            </div>
            <div
              className="columns"
              style={{ margin: "0", justifyContent: "center" }}
            >
              <div
                className="column is-3"
                style={{
                  textAlign: "center",
                  backgroundColor: "#f6f6f6",
                  margin: "1%"
                }}
              >
                <img
                  src="/img/user-regular.svg"
                  className="timer-num"
                  style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                  width="100px"
                  alt="person foto"
                />
                <br />
                <p className="subtitle">
                  Maciej Banasik
                  <br />
                  <sub>
                    Członek Zarządu
                    <br /> Dyrektor Realizacji
                  </sub>
                </p>
                <br />
              </div>
              <div
                className="column is-3"
                style={{
                  textAlign: "center",
                  backgroundColor: "#f6f6f6",
                  margin: "1%"
                }}
              >
                <img
                  src="/img/user-regular.svg"
                  className="timer-num"
                  style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                  width="100px"
                  alt="person foto"
                />
                <br />
                <p className="subtitle">
                  Małgorzata Karbowiak
                  <br />
                  <sub>
                    Członek Zarządu
                    <br />
                    Główny Projektant
                  </sub>
                </p>
                <br />
              </div>

              <div
                className="column is-3"
                style={{
                  textAlign: "center",
                  backgroundColor: "#f6f6f6",
                  margin: "1%"
                }}
              >
                <img
                  src="/img/user-regular.svg"
                  className="timer-num"
                  style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                  width="100px"
                  alt="person foto"
                />
                <br />
                <p className="subtitle">
                  Paweł Żmijewski
                  <br />
                  <sub>
                    Członek Zarządu
                    <br />
                    Kierownik Serwisu
                  </sub>
                </p>
                <br />
              </div>
            </div>
            <br />
            <br />
          </div>

          <br />

          <div className="card" style={{ marginBottom: "5%" }}>
            <header
              className="card-header more"
              style={{ alignItems: "center" }}
            >
              <h2
                className="card-header-title subtitle is-size-3"
                style={{ margin: "10px" }}
              >
                Pozostała część zespołu
              </h2>
              <button
                onClick={showPeople}
                className="button standard-button"
                aria-label="load more people"
                style={{ margin: "20px" }}
              >
                Sprawdź pozostałe kontakty
                <img
                  tabIndex="-1"
                  src="/img/angle-down.svg"
                  width="10px"
                  height="10px"
                  alt="more"
                  style={{
                    marginLeft: "20px",
                    marginBottom: "0px",
                    marginTop: "2.5px",
                    filter: "invert(1)",
                    transform: "rotate(90deg)"
                  }}
                />
              </button>
            </header>
            <div id="morePeople" style={{ display: "none" }}>
              <h3
                style={{
                  textAlign: "left",
                  margin: "1%",
                  marginTop: "2%",
                  paddingLeft: "10px",
                  borderLeft: "2px solid #00ff00"
                }}
                className="subtitle is-size-6"
              >
                REALIZACJA{" "}
              </h3>
              <div className="columns" style={{ margin: "0px" }}>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Maciej Banasik
                    <br />
                    <sub>
                      Członek Zarządu
                      <br />
                      Dyrektor Realizacji
                    </sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Bartosz Karbowiak
                    <br />
                    <sub>Dyrektor Techniczny</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Aleksandra Grzesiak
                    <br />
                    <sub>Kierownik Robót</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Oliwia Wolańska
                    <br />
                    <sub>Kierownik Robót</sub>
                  </p>
                  <br />
                </div>
              </div>
              <div className="columns" style={{ margin: "0px" }}>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Stanislav Astrauskas
                    <br />
                    <sub>Kierownik Robót</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Marta Łuczak
                    <br />
                    <sub>Kierownik Robót</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Marcin Przytuła
                    <br />
                    <sub>Kierownik Robót</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Izabela Karkosz
                    <br />
                    <sub>Inżynier Budowy</sub>
                  </p>
                  <br />
                </div>
              </div>

              <div className="columns" style={{ margin: "0px" }}>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Bartłomiej Żmijewski
                    <br />
                    <sub>Inżynier Budowy</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Bartosz Ciura
                    <br />
                    <sub>Inżynier Budowy</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Kamil Olejnik
                    <br />
                    <sub>Inżynier Budowy</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Marta Kosno
                    <br />
                    <sub>Inżynier Budowy</sub>
                  </p>
                  <br />
                </div>
              </div>
              <div className="columns" style={{ margin: "0px" }}>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Filip Jaz
                    <br />
                    <sub>Inżynier Budowy</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Anna Urbańska
                    <br />
                    <sub>Inżynier Budowy</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Bartosz Grabowiecki
                    <br />
                    <sub>Inżynier Budowy</sub>
                  </p>
                  <br />
                </div>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Arkadiusz Książek
                    <br />
                    <sub>Inżynier Budowy</sub>
                  </p>
                  <br />
                </div>
              </div>
              <div className="columns" style={{ margin: "0px" }}>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Adrian Balicki
                    <br />
                    <sub>Inżynier Budowy</sub>
                  </p>
                  <br />
                </div>
                <div
                  className="column mob-hide"
                  style={{ textAlign: "center", margin: "1%" }}
                ></div>
                <div
                  className="column mob-hide"
                  style={{ textAlign: "center", margin: "1%" }}
                ></div>
                <div
                  className="column mob-hide"
                  style={{ textAlign: "center", margin: "1%" }}
                ></div>
              </div>
              <h3
                style={{
                  textAlign: "left",
                  margin: "1%",
                  marginTop: "2%",
                  paddingLeft: "10px",
                  borderLeft: "2px solid #00ff00"
                }}
                className="subtitle is-size-6"
              >
                SERWIS{" "}
              </h3>
              <div className="columns" style={{ margin: "0px" }}>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Paweł Żmijewski
                    <br />
                    <sub>
                      Członek Zarządu
                      <br />
                      Kierownik Serwisu
                    </sub>
                  </p>
                  <br />
                </div>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Małgorzata Frazik
                    <br />
                    <sub>Z-ca Kierownika Serwisu</sub>
                  </p>
                  <br />
                </div>
                <div
                  className="column mob-hide"
                  style={{ textAlign: "center", margin: "1%" }}
                ></div>
                <div
                  className="column mob-hide"
                  style={{ textAlign: "center", margin: "1%" }}
                ></div>
              </div>
              <h3
                style={{
                  textAlign: "left",
                  margin: "1%",
                  marginTop: "2%",
                  paddingLeft: "10px",
                  borderLeft: "2px solid #00ff00"
                }}
                className="subtitle is-size-6"
              >
                LOGISTYKA{" "}
              </h3>
              <div className="columns" style={{ margin: "0px" }}>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Marta Górska
                    <br />
                    <sub>Kierownik Logistyki</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Monika Kaczorowska
                    <br />
                    <sub>Specjalista Logistyki</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Natalia Domeradzka
                    <br />
                    <sub>Specjalista Logistyki</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Małgorzata Jordan
                    <br />
                    <sub>Specjalista Logistyki</sub>
                  </p>
                  <br />
                </div>
              </div>
              <div className="columns" style={{ margin: "0px" }}>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Barbara Piekarczyk
                    <br />
                    <sub>Specjalista Logistyki</sub>
                  </p>
                  <br />
                </div>
                <div
                  className="column mob-hide"
                  style={{ textAlign: "center", margin: "1%" }}
                ></div>
                <div
                  className="column mob-hide"
                  style={{ textAlign: "center", margin: "1%" }}
                ></div>
                <div
                  className="column mob-hide"
                  style={{ textAlign: "center", margin: "1%" }}
                ></div>
              </div>
              <h3
                style={{
                  textAlign: "left",
                  margin: "1%",
                  marginTop: "2%",
                  paddingLeft: "10px",
                  borderLeft: "2px solid #00ff00"
                }}
                className="subtitle is-size-6"
              >
                OFERTOWANIE{" "}
              </h3>
              <div className="columns" style={{ margin: "0px" }}>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Magdalena Sigda
                    <br />
                    <sub>Kierownik Ofertowania</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Alicja Klatt
                    <br />
                    <sub>Ekspert ds. Analizy Ofert</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Kornelia Nosal
                    <br />
                    <sub>Specjalista Ofertowania</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Aleksandra Przybylska
                    <br />
                    <sub>Specjalista Ofertowania</sub>
                  </p>
                  <br />
                </div>
              </div>
              <div className="columns" style={{ margin: "0px" }}>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Edyta Dąbrowska
                    <br />
                    <sub>Specjalista Ofertowania</sub>
                  </p>
                  <br />
                </div>
                <div
                  className="column mob-hide"
                  style={{ textAlign: "center", margin: "1%" }}
                ></div>
                <div
                  className="column mob-hide"
                  style={{ textAlign: "center", margin: "1%" }}
                ></div>
                <div
                  className="column mob-hide"
                  style={{ textAlign: "center", margin: "1%" }}
                ></div>
              </div>
              <h3
                style={{
                  textAlign: "left",
                  margin: "1%",
                  marginTop: "2%",
                  paddingLeft: "10px",
                  borderLeft: "2px solid #00ff00"
                }}
                className="subtitle is-size-6"
              >
                PROJEKTOWANIE{" "}
              </h3>
              <div className="columns" style={{ margin: "0px" }}>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Małgorzata Karbowiak
                    <br />
                    <sub>
                      Członek Zarządu <br />
                      Główny Projektant
                    </sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Dominika Owczarek
                    <br />
                    <sub>Asystent Projektanta</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Zuzanna Rzemińska
                    <br />
                    <sub>Asystent Projektanta</sub>
                  </p>
                  <br />
                </div>

                <div className="column"></div>
              </div>
              <h3
                style={{
                  textAlign: "left",
                  margin: "1%",
                  marginTop: "2%",
                  paddingLeft: "10px",
                  borderLeft: "2px solid #00ff00"
                }}
                className="subtitle is-size-6"
              >
                KSIĘGOWOŚĆ I ADMINISTRACJA{" "}
              </h3>
              <div className="columns" style={{ margin: "0px" }}>
                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Jolanta Miszczyk
                    <br />
                    <sub>Główna Księgowa</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Adriana Gruszecka
                    <br />
                    <sub>Kierownik Administacji</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Paulina Bęcek-Cichuta
                    <br />
                    <sub>Kierownik Operacyjny</sub>
                  </p>
                  <br />
                </div>

                <div
                  className="column"
                  style={{
                    textAlign: "center",
                    backgroundColor: "#f6f6f6",
                    margin: "1%"
                  }}
                >
                  <img
                    src="/img/user-regular.svg"
                    className="timer-num"
                    style={{ backgroundColor: "#00ff00", margin: "20px 0px" }}
                    width="100px"
                    alt="person foto"
                  />
                  <br />
                  <p className="subtitle">
                    Marta Socha
                    <br />
                    <sub>Specjalista ds. Kadr i Płac</sub>
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="is-bold" style={{textAlign:'center', padding:'2.5% 5%'}}>
      <p className="is-size-3">Współpracujemy z najlepszymi</p>
      <p>Nowoczesne systemy dla twojej firmy od światowej klasy producentów.</p>
      </div>

      <Slider1 />*/}

        {/*  <div style={{textAlign:'center'}}>
      <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/">Home</Link>
      <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/oferta/">Oferta</Link>
      <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/aktualnosci/">Aktualności</Link>
      <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/realizacje/">Realizacje</Link>
      </div>
      <div style={{textAlign:'center'}}>
      <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/kariera/">Kariera</Link>
      <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/sponsoring/">Sponsoring</Link>
      <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/unia-europejska/">UE</Link>
      <Link className="button is-size-6 rexer-button" style={{margin:'2%'}} to="/kontakt/">Kontakt</Link>
      </div> <Newsletter />*/}

        <CTA />
      </Layout>
    );
  }
}

export default AboutPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

/*

function countDown() {
//initial numbers
let x = 0;
let x1 = 0;
let x2 = 0;
let x3 = 0;

var a =  setInterval( function(){

    if (document.getElementById('t1')!=null){
        if (x<new Date().getFullYear() - 2014){
          x++;
          document.getElementById('t1').innerHTML = x;
        } else if (x===new Date().getFullYear() - 2014){
          clearInterval(a);
        }
        } else {
          clearInterval(a);
        }
      }, 1000);


var b =  setInterval( function(){

    if (document.getElementById('t2')!=null){
        if (x1<272){
          x1++;
          document.getElementById('t2').innerHTML = x1;
        } else if (x1===272){
          clearInterval(b);
        }
        }else {
          clearInterval(b);
        }
      }, 100);

var c =  setInterval( function(){

    if (document.getElementById('t3')!=null){
        if (x2<5777){
          x2++;
          document.getElementById('t3').innerHTML = x2;
        } else if (x2===5777){
          clearInterval(c);
        }
      } else {
        clearInterval(c);
      }
      }, 10);

var d =  setInterval( function(){

    if (document.getElementById('t4')!=null){
        if (x3<3210){
          x3++;
          document.getElementById('t4').innerHTML = x3;
        } else if (x3===3210){
          clearInterval(d);
        }
      } else {
        clearInterval(d);
      }
      }, 20);

}

*/

function showPeople(e) {
  if (document.getElementById("morePeople").style.display === "none") {
    //console.log('show')
    document.getElementById("morePeople").style.display = "block";
    e.target.firstChild.nextSibling.style.transform = "rotate(-90deg)";
    //e.target.style.filter = 'invert(1)'
    //console.log(e.target.firstChild.nextSibling)
    e.target.parentElement.style.background = "#00ff00";
    e.target.parentElement.firstChild.style.color = "white";
  } else {
    document.getElementById("morePeople").style.display = "none";
    e.target.firstChild.nextSibling.style.transform = "rotate(90deg)";
    //e.target.style.filter = 'invert(0)'
    e.target.parentElement.style.background = "none";
    e.target.parentElement.firstChild.style.color = "inherit";
  }
}

function initCount() {
  var lazyBackgrounds = [].slice.call(document.querySelectorAll(".animate"));

  if (
    "IntersectionObserver" in window &&
    "IntersectionObserverEntry" in window &&
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  ) {
    let lazyBackgroundObserver = new IntersectionObserver(function(
      entries,
      observer
    ) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          if (entry.target.firstChild.classList.contains("a5")) {
            gsap.to(".a5", { y: 0, duration: 3 });
            console.log("enter viewport a5");
          }
          /*else {
            countDown();
          }*/
          lazyBackgroundObserver.unobserve(entry.target);

          //console.log('enter viewport');
        }
      });
    });
    lazyBackgrounds.forEach(function(lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });
  }
}
